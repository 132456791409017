<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo/>
          <h2 class="brand-text text-primary ml-1">
            Fiberz
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Adventure starts here 🚀
        </b-card-title>
        <b-card-text class="mb-2">
          Make your app management easy and fun!
        </b-card-text>

        <!-- form -->
        <validation-observer ref="registerForm">
          <b-form
              class="auth-register-form mt-2"
              @submit.prevent="validationForm">

            <!-- email -->
            <b-form-group
                label="Email"
                label-for="email"
            >
              <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
              >
                <b-form-input
                    id="email"
                    v-model="regEmail"
                    :state="errors.length > 0 ? false:null"
                    name="email"
                    placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group
                label="Password"
                label-for="password"
            >
              <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required"
              >
                <b-input-group
                    :class="errors.length > 0 ? 'is-invalid':null"
                    class="input-group-merge"
                >
                  <b-form-input
                      id="password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      name="password"
                      placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
              >
                I agree to
                <b-link>privacy policy & terms</b-link>
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
                block
                type="submit"
                variant="primary"
            >
              Sign up
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>Already have an account? </span>
          <b-link :to="{name:'login'}">
            <span>Sign in instead</span>
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Register v1 -->
    </div>
  </div>

</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {required, email} from '@validations'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      regEmail: '',
      password: '',
      status: '',

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.$http.post("/user/register", {email: this.$data.regEmail, password: this.$data.password}).then(async (response) => {
            if (!response) return;

            localStorage.setItem("token", response.data.token);
            await this.$router.push("/my");
          })
              .catch((error) => {
                console.log(error.response.data.errors)
                this.$refs.registerForm.setErrors(error.response.data.errors);
              })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '/src/@core/scss/vue/pages/page-auth.scss';
</style>
